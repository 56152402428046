import api, { API_PATHS } from "@/api/index";
import { NftStakingModel } from "@/api/models/nftStaking.model";
import { BigNumber } from "bignumber.js";
import BLOCKCHAIN from "@/constants/blockchain";
import { TokenTransfer } from "@multiversx/sdk-core/out";

class NftStakingClient {
  async nftStakingPool(type: string, address?: string): Promise<NftStakingModel | null> {
    try {
      const response = await api.get(API_PATHS.NFT_STAKING_POOL, { type }, { address });
      const data = response.data;

      return {
        ...data,
        reward: {
          ...data.reward,
          rewardPerDay: TokenTransfer.fungibleFromBigInteger(
            data.reward.rewardToken,
            data.reward.rewardPerDay,
            data.reward.rewardTokenDecimals
          ),
          rewardPerNftPerDay: new BigNumber(data.reward.rewardPerNftPerDay),
          rewardCapacity: new BigNumber(data.reward.rewardCapacity),
        },
        positions: (data?.positions || []).map((position) => ({
          ...position,
          rewardPerShare: new BigNumber(position.rewardPerShare),
          calculatedReward:
            type === BLOCKCHAIN.NFT_POOL_TYPES.VIP
              ? TokenTransfer.egldFromBigInteger(position.calculatedReward)
              : TokenTransfer.fungibleFromBigInteger(
                  BLOCKCHAIN.TOKEN_IDENTIFIER,
                  position.calculatedReward,
                  BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
                ),
        })),
      };
    } catch (e) {
      console.error(e);

      return null;
    }
  }
}

const nftStakingClient = new NftStakingClient();

export default nftStakingClient;
