import { pagesEN } from "@/translations/en/pages";

export const enMessages = {
  pages: pagesEN,
  menu: {
    games: 'Games',
    hideAndSeek: "Card Flip",
    tokenFlip: "Token Flip",
    rps: "RPS",
    play1v1: "Battle Arena",
    nutsRoulette: "$NUTS Roulette",
    stakingNuts: "$NUTS Staking",
    stakingNfts: "NFT Staking",
    raffle: "Raffle",
    blackjack: "Blackjack",
    roulette: "Roulette",
    stats: "Stats",
    leaderboard: "Leaderboard",
    referrals: "Referrals",
    myNfts: "My NFTs",
    docs: "Docs",
    login: "Connect Wallet",
    logout: "Logout",
    account: {
      balance: "Balance",
      explorer: "View on Explorer",
    },
  },
  login: {
    title: "Connect Wallet",
    extension: "MultiversX DeFi Wallet",
    maiar: {
      title: "xPortal App",
      info: "Scan the QR code using xPortal",
      mobileInfo: "or click the button below to open the App",
      mobileLink: "xPortal Login",
    },
    ledger: {
      title: "Ledger",
      connect: "Connect Ledger",
      info: "Unlock your device & open the Elrond App",
      error: "Check if Elrond app is open on Ledger",
      addresses: {
        address: "Address",
        balance: "Balance",
        confirm: "Confirm",
      },
      confirm: {
        title: "Confirm Ledger Address",
        security:
          "For security, please confirm that your address is the same as the one shown on your Ledger device screen now.",
        approve: "Select Authorize on your device to confirm, or reject if they don't match",
      },
    },
    webWallet: "MultiversX Web Wallet",
    xAlias: "xAlias",
  },
  hideAndSeek: {
    gameMode: {
      select: "Set Multiplier",
    },
    title: "Select your lucky card",
    chooseBet: "Choose your bet",
    shuffle: "SHUFFLE",
    times: "1 time | {count} times",
    result: {
      onlyWon: "You were LUCKY and won {won} time(s)!",
      oneWon: "You were LUCKY and won!",
      onlyLost: "You were UNLUCKY and lost {lost} time(s)...",
      oneLost: "You were UNLUCKY and lost...",
      mixed: "You won {won} time(s) and lost {lost} time(s).",
    },
    recentGames: {
      win: "WIN",
      lose: "LOSE",
      draw: "DRAW",
      bet: "{address} has bet <strong>{amount}</strong> ",
      status: {
        onlyWon2: " and <strong>doubled</strong> his money <strong>{won} time(s)</strong>!",
        onlyWon3: " and <strong>tripled</strong> his money <strong>{won} time(s)</strong>!",
        oneWon2: " and <strong>doubled</strong> his money!",
        oneWon3: " and <strong>tripled</strong> his money!",
        onlyLost: " and lost <strong>{lost} time(s)</strong>.",
        oneLost: " and lost.",
        mixed: ", won <strong>{won} time(s)</strong> and lost <strong>{lost} time(s)</strong>",
      },
    },
  },
  tokenFlip: {
    title: "Heads or Tails?",
    chooseBet: "Choose your bet",
    shuffle: "SHUFFLE",
    times: "1 time | {count} times",
    result: {
      onlyWon: "You were LUCKY and won {won} time(s)!",
      oneWon: "You were LUCKY and won!",
      onlyLost: "You were UNLUCKY and lost {lost} time(s)...",
      oneLost: "You were UNLUCKY and lost...",
      mixed: "You won {won} time(s) and lost {lost} time(s).",
    },
    recentGames: {
      win: "WIN",
      lose: "LOSE",
      draw: "DRAW",
      bet: "{address} has bet <strong>{amount}</strong> ",
      status: {
        onlyWon: " and <strong>doubled</strong> his money <strong>{won} time(s)</strong>!",
        oneWon: " and <strong>doubled</strong> his money!",
        onlyLost: " and lost <strong>{lost} time(s)</strong>.",
        oneLost: " and lost.",
        mixed: ", won <strong>{won} time(s)</strong> and lost <strong>{lost} time(s)</strong>.",
      },
      goNuts: 'GO NUTS',
    },
  },
  rps: {
    title: "Select your weapon",
    chooseBet: "Choose your bet",
    play: "PLAY",
    draw: "It' a DRAW!",
    win: "You were lucky and WON!",
    lose: "You were unlucky and LOST...",
    recentGames: {
      win: "WIN",
      lose: "LOSE",
      draw: "DRAW",
      bet: "{address} has bet <strong>{amount}</strong> ",
      status: {
        0: " and lost.",
        1: " and made a draw.",
        2: " and <strong>doubled</strong> his money.",
      },
    },
  },
  oneVsOne: {
    title: "Ready to Fight",
    description:
      "Take on a challenge from a friend or make your own. A winner will be chosen at random by the Smart Contract and receive the prize! ",
    list: {
      allFights: {
        title: "Recent Plays",
        fight: "{address} fought with {gameAddress} at {time} for <strong>{amount}</strong> and ",
        win: "Won",
        lose: "Lost",
      },
      myFights: "My Fights",
      create: "Create Game",
      none: "😔 There are currently no games.",
    },
    fee: {
      referer: {
        title: {
          nutsCollection: "2% fee if NUTS token OR 4% otherwise",
          otherCollection: "3% fee if NUTS token OR 4% fee otherwise",
        },
        tooltip: {
          nutsCollection:
            "if NUTS token (1.5% ➜ Burn 🔥, 0.5% ➜ Referrer); if EGLD Token (3.5% ➜ Staking Rewards Wallet, 0.5% ➜ Referrer); otherwise (4% ➜ Staking Rewards Wallet / Burn 🔥, 5 $NUTS ➜ Referrer)",
          otherCollection:
            "if NUTS token (0.75% ➜ Burn 🔥, 1.75% ➜ Collection, 0.5% ➜ Referrer); if EGLD Token (1.75% ➜ ElrondBet.net Treasury Wallet, 1.75% ➜ Collection, 0.5% ➜ Referrer); otherwise (2% ➜ Staking Rewards Wallet / Burn 🔥, 2% ➜ Collection, 5 $NUTS ➜ Referrer)",
        },
      },
      noReferer: {
        title: {
          nutsCollection: "2.5% fee if NUTS token OR 4.5% otherwise",
          otherCollection: "3.5% fee if NUTS token OR 4.5% fee otherwise",
        },
        tooltip: {
          nutsCollection:
            "if NUTS token (2.5% ➜ Burn 🔥); otherwise (4.5% ➜ Staking Rewards Wallet / Burn 🔥)",
          otherCollection:
            "if NUTS token (1.5% ➜ Burn 🔥, 2% ➜ Collection); otherwise (2.5% ➜ Staking Rewards Wallet / Burn 🔥, 2% ➜ Collection)",
        },
      },
    },
    create: {
      title: "Organize a FIGHT",
      submit: "Create Game",
      balance: "Current Balance",
      fightingFor: "Fighting for",
      success: "Game successfully created!",
    },
    game: {
      size: "👊 Fight For",
      host: "Game Host",
      accept: "⚔ Accept Challenge",
    },
    cancel: {
      confirm: "Are you sure you want to cancel this game?",
      success: "Game was successfully cancelled.",
      errorNotExists: "Someone already accepted the challenge and the game no longer exists...",
    },
    play: {
      balanceError: "Not enough {token} balance to accept this challenge...",
      confirm: "Are you sure you want to accept this challenge?",
      confirmText: "By accepting, you will send <strong>{amount}</strong> to the contract.",
      lucky: "You were LUCKY and won!",
      unlucky: "You were UNLUCKY and lost...",
      errorNotExists: "Someone else already accepted the challenge and the game no longer exists...",
    },
  },
  nutsRoulette: {
    title: "NUTS Roulette",
    chooseNbBullets: "Choose number of acorns",
    play: "PLAY",
    rewards: {
      jackpot: "Jackpot",
      nextGen: "Next Gen Jackpot",
      superJackpot: "Super Jackpot",
      specialChance: "{chance}% special chance",
      only6BulletsToolTip: 'Only applies if playing with 6 acorn',
      only6BulletsNftToolTip: 'Only applies if playing with 6 acorn and using appropriate NFT!',
      extraSpecial: "extra special reward",
    },
    type: {
      jackpot: "Normal Jackpot",
      nextGen: "Next Gen Jackpot",
      superJackpot: "SUPER Jackpot",
    },
    result: {
      won: 'You were LUCKY and won the JACKPOT!',
      wonNextGen: 'You were LUCKY and won the Next Gen JACKPOT!',
      wonSuper: 'You were LUCKY and won the SUPER JACKPOT!',
      wonSpecial: 'You were DOUBLE LUCKY and also won a Squirrel NFT! 🎉',
      wonSpecialNextGen: 'You were DOUBLE LUCKY and also won a NextGen Squirrel NFT! 🎉',
      wonSpecialSuper: 'You were DOUBLE LUCKY and also won the Special WEGLD Prize! 🎉',
      lost: 'You were UNLUCKY and lost...'
    },
    info: {
      chances: '1/6 win chances for every acorn',
      fee: '10% fee',
      feeReferer: '11% fee',
      feeTooltip: '10% fee 5% ➜ ElrondBet.net, 5% ➜ Burn 🔥',
      feeTooltipReferer: '11% fee 5% ➜ ElrondBet.net, 5% ➜ Burn 🔥, 1% ➜ Referrer',
      goNuts: 'A 10% discount was applied on the WEGLD amount required'
    }
  },
  staking: {
    nuts: {
      title: "NUTS Staking",
      rewards: {
        multiplier: "Multiplier",
        unlocked: "Fully unlocked",
        apr: "Current APR",
        total: "Total Staked",
        maxApr: "Max APR",
        poolLabel: "Pool",
        pools: {
          1: "Bronze",
          2: "Silver",
          3: "Gold",
          4: "Platinum",
        },
      },
      stake: {
        current: "Your Stake",
        button: "Stake",
        confirm: "Are you sure you want to stake these NUTS tokens? They will be fully unlocked only after {days} days",
        success: "NUTS successfully staked!",
        tooltip: "Staking will also compound your rewards",
        withdrawable: "Withdrawable",
      },
      unstake: {
        button: "Withdraw",
        buttonUnstake: "Unstake",
        confirm: "Are you sure you want to withdraw these NUTS tokens?",
        success: "NUTS successfully withdrawn!",
        tooltipWithdraw: "You can start withdrawing {time}",
        tooltipLocked: "NUTS will be fully unlocked {time}",
        tooltip: "Unstaking will also claim your rewards",
      },
      claim: {
        reward: "Your Rewards",
        compound: "Compound",
        button: "Claim",
        success: "Rewards successfully claimed!",
        successCompound: "Rewards successfully compounded!",
        tooltip: "This will also reset your unlock time",
      },
      manage: {
        max: "Max",
        unset: "Unset",
        error: "Amount is too large",
        confirm: "Confirm",
      },
    },
    nft: {
      title: "NFT Staking",
      upgrade: {
        button: "Upgrade {nb} NFTs",
        info: "You have {nb} NFTs that need to be upgraded. Please Unstake your NFTs. An Upgrade button will appear below after unstaking.",
        confirm:
          "You are about to upgrade {nb} NFTs. They will be sent to the upgrade contract and new NFTs will be returned to you.",
        success: "NFTs successfully upgraded!",
      },
      rewards: {
        token: "Token",
        perDay: "Reward / Day",
        perNft: "Per Day / NFT",
        total: "Total Rewards",
        nftsStaked: "NFTs Staked",
        end: "Renews",
        floorPriceApr: "*Based on current NFT floor price. Not guaranteed, subject to change.",
      },
      staked: {
        title: "STAKED",
        claim: "CLAIM",
        unstake: "UNSTAKE",
      },
      unstaked: {
        title: "UNSTAKED",
        stake: {
          button: "Stake {nb} NFTs",
          buttonSets: "Stake {nbSets} SET(s) + {nbNfts} NFTs",
          selectAll: "Select All",
          unselectAll: "Unselect All",
          confirm: "Are you sure you want to stake {nb} NFTs? They will be unlocked only after {days} days",
          success: "NFTs successfully staked!",
        },
      },
      selected: {
        button: "Select",
        active: "Selected",
      },
      position: {
        unstake: {
          button: "Unstake {nbNfts} NFT(s)",
          tooltipLocked: "You can unstake {time}",
          tooltip: "Unstaking will also claim your rewards",
          confirm: "Are you sure you want to unstake {nbNfts} NFTs?",
          success: "NFTs successfully unstaked!",
          viewNfts: "View {nbNfts} NFTs",
        },
        claim: "Claim Rewards",
        claimSuccess: "Rewards successfully claimed!",
        claimTooltip: "This will also reset your unlock time",
        reward: "Your Rewards",
        info: '{nbNfts} NFTs earning <span class="text-warning">{total}</span> / day',
        infoVip: '{nbSets} SET(s) + {nbNfts} NFTs earning <span class="text-primary">{total}</span> / day',
      },
    },
    pools: {
      title: "Farms & Pools",
      rewards: {
        total: "Total Staked",
        apr: "APR",
        perDay: "Reward / Day",
        end: "Ends",
        ended: "Ended",
        vip: {
          swap: "Swap VIP",
          sell: "Sell / Buy VIP",
          ended: "VIP tokens out of stock",
        },
        farm: {
          addLP: "Add Liquidity",
          swapDeez: 'Swap DEEZ',
          tooltip: "Search for NUTS, add liquidity to NUTS - EGLD pool and then come back here",
        },
      },
      position: {
        current: "Your Stake",
        stake: "Stake",
        stakeConfirm:
          "Are you sure you want to stake these tokens? They will be fully unlocked only after {days} days, or you can unlock them earlier for a 3% fee",
        stakeSuccess: "Tokens successfully staked!",
        nftStaked: "5% boost if Squirrel NFT Staked",
        nftStakedTooltip:
          "If you have a Squirrel NFT staked and the boost is not applied, please Claim Rewards or Stake more tokens",
        unstake: {
          button: "Withdraw",
          tooltipFee: "You can unstake now for a 3% fee or {time} for no fee",
          tooltip: "Unstaking will also claim your rewards",
          confirm: "Are you sure you want to unstake these tokens?",
          confirmFee: "Are you sure you want to unstake these tokens? You will pay a 3% fee",
          success: "Tokens successfully unstaked!",
        },
        info: "TVL: {tvl}",
        reward: "Your Rewards",
        claim: "Claim Rewards",
        claimSuccess: "Rewards successfully claimed!",
        claimTooltip: "This will also reset your unlock time",
        rewardPerDay: "Your Reward / Day",
      },
    },
  },
  blackjack: {
    logout: "Logout",
    clearBet: "Clear Bet",
    deal: "DEAL",
    placeBet: "Place your bet!",
    status: {
      blackjack: "You scored a BLACKJACK and WON {token}!",
      0: "Unfortunately you lost {token}...",
      1: "You WON {token}!",
      2: "You reached a STANDOFF with the dealer. {token} was returned to you",
    },
    awt: "⌛ Average Waiting Time per tx: 50s",
    hand: {
      dealer: "Dealer",
      player: "You",
    },
  },
  roulette: {
    selectAColor: "Select a color:",
    chooseBet: "Choose your bet:",
    placeBet: "Place bet",
    recentGames: "Recent Games:",
    tips: "💡 You can farm $EGLD with our ",
    tokens: {
      egld: "EGLD",
      nuts: "NUTS",
    },
  },
  raffle: {
    title: "ElrondBet Raffle",
    text: "<strong>👋 Welcome to our Raffle!</strong> <br> Take a chance to win cool prizes while also having fun increasing your wallet balance.",
    prize: "<strong>Prize: 3 EGLD 🎁</strong>",
    totalTickets: "TOTAL Tickets",
    buyTickets: "Buy Tickets",
    yourTickets: "YOUR Tickets",
    winner: "🏆 Winner",
    previousWinner: "🏆 Previous Winner",
    winnerTickets: "Winner Tickets",
    nextRaffle: "⏭ Next Raffle",
    info: "ℹ 1 Bet = 1 Ticket (Any Game, Any Token)",
    faq: {
      title: "FAQ 🙋",
      q1: "How does the Raffle works?",
      r1: "Once the Raffle begins, you can get tickets using $NUTS or by placing bets on the platform. At the end of the raffle period, a random selection will be conducted to determine the winner or winners (if multiple prizes).",
      q2: "How many tickets can I buy/get?",
      r2: "You can buy/get as many tickets as you want.",
      q3: "How many winners will there be?",
      r3: "There will be multiple winners if there are multiple prizes. If there is only one prize, there will be only one winner.",
      q4: "Does it help to have more tickets?",
      r4: "Definitely! More tickets means more chances to win the prize.",
      q5: "Is this a no-loss raffle?",
      r5: "No, the tokens won't be returned for the losing tickets.",
      q6: "How is this different than the xLaunchpad lottery?",
      r6: "On xLaunchpad lottery, you win the chance to buy. Here, you win a prize.",
    },
  },
  referrals: {
    title: "Referrals",
    info: 'Refer people to our platform and earn <strong class="text-warning">0.5%</strong> of their fee on <strong>EVERY GAME</strong> played using $EGLD or $NUTS OR earn up to 15 $NUTS per play for other tokens.',
    info2: 'The person you refer will also get a reduced fee of <strong class="text-warning">0.5%</strong>.',
    info3:
      "Just share the link below with someone and from their next game they will be automatically counted as your referral!",
    copied: "URL has been copied!",
    number: 'You currently have <strong class="text-warning">{number}</strong> referrals.',
    rewards: {
      title: "Earned Referral Rewards",
      claim: "Claim",
      info: "No need to claim too often in order to save on gas fees. The rewards will be here accumulating for you.",
    },
    referrer: "You were referred by:",
    success: "Successfully claimed earned referral rewards!",
  },
  user: {
    nfts: {
      title: "My NFTs",
      collection: "Collection",
      rarity: "Rarity",
      none: "😔 You don't have any {label} NFTs on your account",
      vipCards: "VIP Cards",
      squirrels: "SquirrelS",
    },
  },
  general: {
    loadNfts: 'Load More',
    loadMore: "Load More",
    collection: {
      select: "Select Game Mode",
    },
    token: {
      balance: "Balance",
    },
    custom: {
      jex: "JEXchange",
      kro: "Krogan",
      water: "Water",
      rare: "RARE",
      mermaid: "MERMAID",
      padawan: "PADAWAN",
    },
    fee: {
      referer: {
        nutsCollection: {
          nutsToken: "2% fee 1.5% ➜ Burn 🔥, 0.5% ➜ Referrer",
          egldToken: "7% fee 2% ➜ ElrondBet.net, 4.5% ➜ VIP Staking Rewards, 0.5% ➜ Referrer",
          otherToken: "7% fee 2.25%% ➜ ElrondBet.net, 4.75% ➜ VIP Staking Rewards, 5 OR 15 $NUTS ➜ Referrer",
          jexToken: "7% fee 2% ➜ Burn 🔥, 4.75% ➜ VIP Staking Rewards, 5 OR 15 $NUTS ➜ Referrer",
        },
        otherCollection: {
          nutsToken: "6% fee 1.75% ➜ Burn 🔥, 3.75% ➜ Collection, 0.5% ➜ Referrer",
          egldToken: "7% fee 2.5% ➜ VIP Staking Rewards, 3.75% ➜ Collection, 0.5% ➜ Referrer",
          otherToken: "7% fee 2.75% ➜ VIP Staking Rewards, 4% ➜ Collection, 5 OR 15 $NUTS ➜ Referrer",
          jexToken: "7% fee 3% ➜ Burn 🔥, 4% ➜ Collection, 5 OR 15 $NUTS ➜ Referrer",
        },
      },
      noReferer: {
        nutsCollection: {
          nutsToken: "2.5% fee ➜ Burn 🔥",
          egldToken: "7.5% fee 2.75% ➜ ElrondBet.net, 4.75% ➜ VIP Staking Rewards",
          otherToken: "7.5% fee 2.75% ➜ ElrondBet.net, 4.75% ➜ VIP Staking Rewards",
          jexToken: "7.5% fee 2.5% ➜ Burn 🔥, 4.75% ➜ VIP Staking Rewards",
        },
        otherCollection: {
          nutsToken: "6.5% fee 2.5% ➜ Burn 🔥, 4% ➜ Collection",
          egldToken: "7.5% fee 3.25% ➜ VIP Staking Rewards, 4% ➜ Collection",
          otherToken: "7.5% fee 3.25% ➜ VIP Staking Rewards, 4% ➜ Collection",
          jexToken: "7.5% fee 3.5% ➜ Burn 🔥, 4% ➜ Collection",
        },
      },
    },
    goNuts: {
      title: 'GO NUTS*',
      help: 'Buy NUTS automatically for lower fees.<br /> <strong>Rewards are won in NUTS!</strong>',
      help2: 'Buy NUTS automatically for lower fees.'
    }
  },
  modal: {
    close: "Close",
  },
  toast: {
    transactions: "Transactions",
    sign: "Sign Transactions",
    signMessage: "Check your wallet to sign your transactions and then come back to the site",
  },
  messages: {
    error: {
      transaction: "An error has occurred while submitting the transaction...Please try again later.",
      general: "An error has occurred...Please try again later.",
    },
    confirm: {
      ok: "Continue",
      cancel: "Cancel",
    },
    balanceError: "Not enough {token} balance...",
    minBetError: "The min bet is {amount} {token}...",
  },
};
