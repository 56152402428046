import nutsStakingContract from "@/contracts/nutsStaking";
import nutsStaking, { LOCKING_PERIODS } from "@/contracts/nutsStaking";
import elrondHelper from "@/helpers/elrond";
import BLOCKCHAIN from "@/constants/blockchain";
import elrondApiHelper from "@/helpers/elrondApi";
import storeHelper, { LIST } from "@/helpers/store";
import transformHelper, { STAKED_POSITION } from "@/helpers/transformHelper";
import { TokenTransfer } from "@multiversx/sdk-core";
import ELROND from "@/constants/elrond";
import { BigNumber } from "bignumber.js";
import storageHelper from "@/helpers/storage";
import TRANSACTION_TYPES from "@/constants/transactionTypes";

export const STAKING_GETTERS = {
  REWARDS: "stakingRewards",
  CURRENT_BLOCK: "stakingCurrentBlock",
  POSITIONS: "stakingPositions",
};

export const STAKING_MUTATIONS = {
  REWARDS: "stakingRewards",
  CURRENT_BLOCK: "stakingCurrentBlock",
  POSITIONS: "stakingPositions",
};

export const STAKING_ACTIONS = {
  GET_REWARDS: "stakingGetRewards",
  STAKE: "stakingStake",
  UNSTAKE: "stakingUnstake",
  CLAIM_REWARDS: "stakingClaimRewards",
  COMPOUND_REWARDS: "stakingCompoundRewards",
  GET_POSITIONS: "stakingGetPositions",
};

export interface STAKED_POSITION_FULL extends STAKED_POSITION {
  calculatedReward: TokenTransfer;
}

export interface REWARD {
  apr: number;
  farmTokenSupply: BigNumber;
  multiplier: number;
}

interface STAKING_STORE_STATE {
  rewards?: Record<number, REWARD>;
  currentBlock?: number;
  positions: LIST<Record<number, STAKED_POSITION_FULL[]>>;
}

export const stakingStore = {
  state: (): STAKING_STORE_STATE => ({
    rewards: null,
    currentBlock: null,
    positions: null,
  }),
  getters: {
    [STAKING_GETTERS.REWARDS](state) {
      return state.rewards;
    },
    [STAKING_GETTERS.CURRENT_BLOCK](state) {
      return state.currentBlock;
    },
    [STAKING_GETTERS.POSITIONS](state) {
      return state.positions;
    },
  },
  mutations: {
    [STAKING_MUTATIONS.REWARDS](state, rewards) {
      state.rewards = rewards;
    },
    [STAKING_MUTATIONS.CURRENT_BLOCK](state, currentBlock) {
      state.currentBlock = currentBlock;
    },
    [STAKING_MUTATIONS.POSITIONS](state, positions) {
      state.positions = positions;
    },
  },
  actions: {
    async [STAKING_ACTIONS.GET_REWARDS]({ commit }) {
      const currentBlock = await elrondHelper.getCurrentBlock(
        elrondHelper.getAddressShard(BLOCKCHAIN.CONTRACTS.NUTS_STAKING)
      );

      commit(STAKING_MUTATIONS.CURRENT_BLOCK, currentBlock);

      const farmTokenSupplies = await nutsStakingContract.getFarmTokenSupplies();

      const rewardPerDay = BLOCKCHAIN.STAKING_TOKEN_PER_BLOCK.multipliedBy(ELROND.BLOCKS_PER_DAY, 10);

      const rewards: Record<number, REWARD> = {};

      let multiplier: number = 1;

      for (const farmTokenSupply of farmTokenSupplies) {
        rewards[farmTokenSupply.lockingPeriod] = {
          apr: Math.min(
            // daily rewards * 365 * 100 / total staked amount
            rewardPerDay
              .multipliedBy(36500 * multiplier, 10)
              .dividedToIntegerBy(farmTokenSupply.supply, 10)
              .toNumber(),
            BLOCKCHAIN.STAKING_MAX_APR
          ),
          farmTokenSupply: farmTokenSupply.supply,
          multiplier,
        };

        multiplier++;
      }

      commit(STAKING_MUTATIONS.REWARDS, rewards);
    },
    async [STAKING_ACTIONS.STAKE](_, { accountElrond, amount, stakedPosition }) {
      storageHelper.setTransactionToWatch(TRANSACTION_TYPES.NUTS_STAKING.STAKE(stakedPosition), accountElrond.nonce);

      return await nutsStaking.stake(
        accountElrond,
        stakedPosition.lockingPeriod,
        amount,
        stakedPosition.amount,
        stakedPosition.nonce
      );
    },
    async [STAKING_ACTIONS.UNSTAKE](_, { accountElrond, amount, nonce }) {
      storageHelper.setTransactionToWatch(TRANSACTION_TYPES.NUTS_STAKING.UNSTAKE(nonce), accountElrond.nonce);

      return await nutsStakingContract.unstake(accountElrond, amount, nonce);
    },
    async [STAKING_ACTIONS.CLAIM_REWARDS](_, { accountElrond, stakedPosition }) {
      storageHelper.setTransactionToWatch(TRANSACTION_TYPES.NUTS_STAKING.CLAIM(stakedPosition.nonce), accountElrond.nonce);

      return await nutsStakingContract.claimRewards(accountElrond, stakedPosition.amount, stakedPosition.nonce);
    },
    async [STAKING_ACTIONS.COMPOUND_REWARDS](_, { accountElrond, stakedPosition }) {
      storageHelper.setTransactionToWatch(TRANSACTION_TYPES.NUTS_STAKING.COMPOUND(stakedPosition.nonce), accountElrond.nonce);

      return await nutsStakingContract.compoundRewards(accountElrond, stakedPosition.amount, stakedPosition.nonce);
    },
    async [STAKING_ACTIONS.GET_POSITIONS]({ commit, getters }, { address, page }) {
      let total = 0;
      const positions: Record<number, STAKED_POSITION_FULL[]> = {};

      if (address) {
        total = await elrondApiHelper.getAddressNftsCount(address, BLOCKCHAIN.META_STAKING_TOKEN_IDENTIFIER);
        const response: STAKED_POSITION[] = await elrondApiHelper.getAddressStakedPositions(
          address,
          BLOCKCHAIN.META_STAKING_TOKEN_IDENTIFIER,
          page,
          total,
          getters[STAKING_GETTERS.CURRENT_BLOCK],
          transformHelper.mapStakedPosition
        );

        const rewards = await nutsStakingContract.calculateMultipleRewards(
          response.map((position) => ({
            amount: position.amount,
            attributes: position.baseStruct,
          }))
        );

        for (let index = 0; index < rewards.length; index++) {
          const tempLockingPeriod = response[index].lockingPeriod;

          if (!(tempLockingPeriod in positions)) {
            positions[tempLockingPeriod] = [];
          }

          positions[tempLockingPeriod].push({
            ...response[index],
            calculatedReward: rewards[index],
          });
        }
      }

      // Add default positions if none exists
      for (const lockingPeriod of LOCKING_PERIODS) {
        if (lockingPeriod in positions) {
          continue;
        }

        positions[lockingPeriod] = [
          {
            amount: new BigNumber(0),
            nonce: 0,
            stakedBlock: 0,
            rewardPerShare: 0,
            unstakedBlock: 0,
            lockingPeriod,
            withdrawable: new BigNumber(0),
            baseStruct: null,
            calculatedReward: TokenTransfer.fungibleFromBigInteger(
              BLOCKCHAIN.TOKEN_IDENTIFIER,
              0,
              BLOCKCHAIN.TOKENS[BLOCKCHAIN.TOKEN_IDENTIFIER].decimals
            ),
          },
        ];

        total++;
      }

      storeHelper.commitList(commit, STAKING_MUTATIONS.POSITIONS, positions, page, total);
    },
  },
};
