
import { useStore } from "vuex";
import { computed } from "vue";
import { USER_GETTERS } from "@/store/user";
import { ROUTES } from "@/router/constants";
import ENV from "@/constants/env";
import SidebarAccount from "@/components/base/SidebarAccount.vue";
import SidebarCommon from "@/components/base/SidebarCommon.vue";

export default {
  name: "SidebarMobile",
  components: { SidebarCommon, SidebarAccount },
  created() {
    this.ROUTES = ROUTES;
    this.ENV = ENV;
  },
  setup() {
    const store = useStore();

    return {
      address: computed(() => store.getters[USER_GETTERS.ADDRESS_ELROND]),
    }
  }
};
